import React from 'react';
import ParticlesBg from 'particles-bg';
import SocialsComponent from './components/SocialsComponent';
function App() {
  return (
    <>
      <div
        style={{
          textAlign: 'center',
          paddingTop: '10%',
          paddingBottom: '10%',
          color: 'darkgray',
        }}
      >
        <h1
          style={{
            fontFamily: 'Pacifico',
            fontSize: '60px',
            margin: '1rem',
            marginBottom: '0px',
          }}
        >
          Mario Petričko
        </h1>
        <h2 style={{ fontFamily: 'Helvetica', paddingBottom: '1rem' }}>
          Backend developer
        </h2>
        <SocialsComponent />
      </div>
      <ParticlesBg type="cobweb" bg={true} />
    </>
  );
}

export default App;
