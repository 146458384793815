import { SocialIcon } from 'react-social-icons';

const SocialsComponent = () => {
  return (
    <div style={{ color: 'darkgray' }}>
      <div className="inline-block hover-zoom" style={{ paddingRight: '1rem' }}>
        <label
          onMouseDown={() =>
            (window.location.href = 'https://github.com/Maracko')
          }
          className="social-text unselectable"
          for="github"
          style={{ paddingRight: '0.2rem', cursor: 'pointer' }}
        >
          <b>Github</b>
        </label>
        <SocialIcon name="github" url="https://github.com/Maracko" />
      </div>
      <div className="inline-block hover-zoom" style={{ paddingRight: '1rem' }}>
        <label
          onMouseDown={() =>
            (window.location.href =
              'https://www.linkedin.com/in/mario-petri%C4%8Dko/')
          }
          className="social-text unselectable"
          for="linkedIn"
          style={{ paddingRight: '0.2rem', cursor: 'pointer' }}
        >
          <b>Linkedin</b>
        </label>
        <SocialIcon
          name="linkedIn"
          url="https://www.linkedin.com/in/mario-petri%C4%8Dko/"
        />
      </div>
      <div className="inline-block hover-zoom">
        <label
          onMouseDown={() =>
            (window.location.href = 'mailto:mario@petricko.dev')
          }
          className="social-text unselectable"
          for="linkedIn"
          style={{ paddingRight: '0.2rem', cursor: 'pointer' }}
        >
          <b>Email</b>
        </label>
        <SocialIcon name="linkedIn" url="mailto:mario@petricko.dev" />
      </div>
    </div>
  );
};

export default SocialsComponent;
